export const FETCH_AREAS = {
  REQUEST: "FETCH_AREAS_REQUEST",
  SUCCESS: "FETCH_AREAS_SUCCESS",
  FAIL: "FETCH_AREAS_FAIL",
  GET_ONE_AREA: "GET_ONE_AREA",
}

export const FETCH_MEMBERS = {
  REQUEST: "FETCH_MEMBERS_REQUEST",
  SUCCESS: "FETCH_MEMBERS_SUCCESS",
  FAIL: "FETCH_MEMBERS_FAIL",
}

export const SEND_EMAIL = {
  REQUEST: "SEND_EMAIL_REQUEST",
  SUCCESS: "SEND_EMAIL_SUCCESS",
  FAIL: "SEND_EMAIL_FAIL",
}

export const HANDLE_MENU_MOBILE = "HANDLE_MENU_MOBILE"
export const SLIDE_MORE_INFO = "SLIDE_MORE_INFO"
export const CLOSE_INFORMATIVE_BANNER = "CLOSE_INFORMATIVE_BANNER"
